<template>
  <div id="drainagePerformance" class="report">
    <div class="exportDrainage-exportRanking">
      <div class="exportDrainage statisticalAnalysis">
        <div class="title-filter">
          <h1>场景出口引流分析</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of typeList" :class="{active:exportDrainage.type===item}" @click="exportDrainage.type=item;exportDrainage_refresh()">{{
                    item
                  }}</span>
              </div>
            </li>
            <li>
              <el-select
                  v-model="exportDrainage.parkingLotId"

                  clearable
                  placeholder="请选择停车场"
                  @change="exportDrainage_refresh"
              >
                <el-option
                    v-for="item in parkingLotList"
                    :key="item.parkingLotId"
                    :label="item.parkingLotName"
                    :value="item.parkingLotId"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <exportDrainage ref="exportDrainage" @refreshRanking="exportDrainage_ranking" class="chartDetail"/>
      </div>
      <div class="exportRanking statisticalAnalysis">
        <exportRanking :list="exportDrainage.ranks" refs="ranking"/>
      </div>
    </div>
    <div class="intersectionPrediction-intersectionPredictionRanking">
      <div class="intersectionPrediction statisticalAnalysis">
        <div class="title-filter">
          <h1>分时段路口预判性分析</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of typeList" :class="{active:intersectionPrediction.type===item}" @click="intersectionPrediction.type=item;intersectionPrediction_refresh()">{{
                    item
                  }}</span>
              </div>
            </li>
            <li>
              <el-select
                  v-model="intersectionPrediction.parkingLotId"

                  clearable
                  placeholder="请选择停车场"
                  @change="intersectionPrediction_refresh"
              >
                <el-option
                    v-for="item in parkingLotList"
                    :key="item.parkingLotId"
                    :label="item.parkingLotName"
                    :value="item.parkingLotId"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <intersectionPrediction ref="intersectionPrediction" @refreshRanking="intersectionPrediction_ranking" class="chartDetail"/>
      </div>
      <div class="intersectionPredictionRanking statisticalAnalysis">
        <intersectionPredictionRanking :list="intersectionPrediction.ranks" refs="ranking"/>
      </div>
    </div>
    <div class="attendanceRate statisticalAnalysis">
      <div class="title-filter">
        <h1>出勤到岗率分析</h1>
        <ul class="filter">
          <li>
            <el-select
                v-model="attendanceRate.parkingLotId"

                clearable
                placeholder="请选择停车场"
                @change="attendanceRate_refresh"
            >
              <el-option
                  v-for="item in parkingLotList"
                  :key="item.parkingLotId"
                  :label="item.parkingLotName"
                  :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
      <attendanceRate ref="attendanceRate"/>
    </div>
  </div>
</template>

<script>
export default {
  components:{
    exportDrainage:()=>import("./exportDrainage"),
    exportRanking:()=>import("./exportRanking"),
    intersectionPrediction:()=>import("./intersectionPrediction"),
    intersectionPredictionRanking:()=>import("./intersectionPredictionRanking"),
    attendanceRate:()=>import("./attendanceRate"),
  },
  data() {
      return {
        typeList: [
          "今日", "本月", "今年"
        ],
        exportDrainage:{
          ranks: [],
          parkingLotId: "",
          type: "今日",
        },
        intersectionPrediction:{
          ranks: [],
          parkingLotId: "",
          type: "今日",
        },
        attendanceRate:{
          parkingLotId: "",
        }
      }
  },
  computed:{
    parkingLotList(){
      return this.$store.state.select.parkingLotList;
    },
  },
  methods:{
    exportDrainage_refresh(){
      this.$refs.exportDrainage.getOptions({
        parkingLotId: this.exportDrainage.parkingLotId,
        type: this.exportDrainage.type
      })
    },
    intersectionPrediction_refresh(){
      this.$refs.intersectionPrediction.getOptions({
        parkingLotId: this.intersectionPrediction.parkingLotId,
        type: this.intersectionPrediction.type
      })
    },
    attendanceRate_refresh(){
      this.$refs.attendanceRate.getOptions({
        parkingLotId: this.intersectionPrediction.parkingLotId
      })
    },
    exportDrainage_ranking(ranks){
      this.$set(this.exportDrainage,"ranks",ranks)
    },
    intersectionPrediction_ranking(ranks){
      this.$set(this.intersectionPrediction,"ranks",ranks)
    }
  }
}
</script>

<style lang="scss">
#drainagePerformance {
  .exportDrainage-exportRanking,
  .intersectionPrediction-intersectionPredictionRanking {
    display: flex;
    justify-content: space-between;
    height: 25rem;
    margin: 1.5rem 0;

    .exportDrainage,.intersectionPrediction {
      width: calc(65% - 1.5rem);
    }

    .exportRanking,.intersectionPredictionRanking {
      width: 35%;
      padding: 1rem 0.63rem !important;
    }
  }
  .attendanceRate{
    width: 100%;
    height: 20rem;
  }
}
</style>
